<template>
  <div>
    <FilterCorsiIscrizioni
      :fromInsegnante="fromInsegnante"
      @getCorsiAreaInsegnanteList="searchCorsiAreaInsegnanteList"
    />
    <TableCorsiIscrizioni
      :fromInsegnante="fromInsegnante"
      @getCorsiAreaInsegnanteList="getCorsiAreaInsegnanteList"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  ref,
} from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { trimInput } from "@/composables/trimInput";

import FilterCorsiIscrizioni from "@/components/components-fit/SNM/accesso-insegnante/FiltriCorsiIscrizioni.vue";
import TableCorsiIscrizioni from "@/components/components-fit/SNM/accesso-insegnante/TableCorsiIscrizioni.vue";
import isEnabled from "@/composables/isEnabled.js";

export default defineComponent({
  name: "corsi-iscrizione",
  components: { FilterCorsiIscrizioni, TableCorsiIscrizioni },
  props: {
    fromInsegnante: {
      type: Boolean,
    },
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Area Riservata");
    });

    const store = useStore();
    const route = useRoute();
    const id_persona = ref(route.params.id_persona);

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const stagione = computed(() => store.getters.stagioneSelected);
    const rowsToSkip = computed(
      () => store.getters.rowsToSkipCorsiAreaInsegnante
    );
    const fetchRows = computed(
      () => store.getters.fetchRowsCorsiAreaInsegnante
    );
    const sortColumn = computed(
      () => store.getters.sortColumnCorsiAreaInsegnante
    );
    const sortOrder = computed(
      () => store.getters.sortOrderCorsiAreaInsegnante
    );

    const id_tipo_corso = computed(
      () => store.getters.tipologiaCorsiAreaInsegnante
    );
    const id_sottotipo_corso = computed(
      () => store.getters.specializzCorsiAreaInsegnante
    );
    const search = computed(
      () => store.getters.denominazioneCorsiAreaInsegnante
    );

    const tutteStagioniCorsiAreaInsegnante = computed(
      () => store.getters.tutteStagioniCorsiAreaInsegnante
    );

    const getCorsiAreaInsegnanteList = () => {
      store.dispatch("setStoreListData", {
        keys: {
          id_persona: id_persona.value,
          stagione: tutteStagioniCorsiAreaInsegnante.value
            ? null
            : stagione.value,
          id_tipo_corso: id_tipo_corso.value,
          id_sottotipo_corso: id_sottotipo_corso.value,
          search: trimInput(search.value),
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          sortcolumn: sortColumn.value,
          sortorder: sortOrder.value,
        },
        apiLink: globalApi.CORSI_AREA_INSEGNANTE_LIST,
        itemName: "corsi_area_insegnante_list",
      });
    };

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedcorsi_area_insegnante_list")
    );

    const searchCorsiAreaInsegnanteList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipCorsiAreaInsegnante");
      getCorsiAreaInsegnanteList();
    };

    getCorsiAreaInsegnanteList();

    return {
      getCorsiAreaInsegnanteList,
      searchCorsiAreaInsegnanteList,
    };
  },
});
</script>
