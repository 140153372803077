<template>
  <div
    class="modal fade"
    tabindex="-10"
    :id="`kt_modal_documenti_partecipante${id_Corso}`"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Documenti</h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-secondary"
            style="border: 0; !important"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="bi bi-x-lg"> </i>
          </div>
          <!--end::Close-->
        </div>

        <div class="modal-body text-start">
          <div class="card-body p-6">
            <div class="ps-5 pe-5">
              <div class="row pb-4"></div>
              <div class="mx-auto my-auto text-center" v-if="!loaded">
                <div class="spinner-border text-gray-600" role="status"></div>
                <span class="text-gray-600 ps-3 fs-2">Loading</span>
              </div>
              <div
                v-else-if="
                  partecipante_doc_list.length === 0 &&
                  loaded &&
                  (status == 200 || status == 204)
                "
                class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
              >
                <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
                <span class="text-gray-700 ps-3 fs-2">
                  Attenzione! Nessun documento presente.
                </span>
              </div>
              <div
                v-else-if="status != 200 && status != 204"
                class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
              >
                <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
                <span class="text-gray-700 ps-3 fs-2">
                  Attenzione! Si è verificato un errore. Riprovare più tardi.
                </span>
                <span
                  type="button"
                  data-bs-toggle=""
                  data-bs-target=""
                  aria-expanded="false"
                  aria-controls=""
                  class="text-gray-600 pt-4"
                >
                  <router-link
                    v-if="isEnabled('fnLinkBachecaCompleta')"
                    to="/bacheca"
                  >
                    <i class="bi bi-house fs-2 text-gray-800"> </i
                  ></router-link>
                  <a
                    v-else-if="!hasBacheca"
                    :href="redirectInsegnanti"
                    class="btn text-hover-primary text-gray-600 fs-5 text-end p-0 m-0"
                  >
                    <i class="bi bi-house fs-2 text-gray-800"> </i>
                  </a>
                  <router-link v-else to="/bacheca-public">
                    <i class="bi bi-house fs-2 text-gray-800"></i>
                  </router-link>
                </span>
              </div>
              <div v-else>
                <Datatable
                  :table-header="tableHeader"
                  :table-data="partecipante_doc_list"
                  :total="record"
                  :current-page="currentPage"
                  :rows-per-page="fetchRows"
                  :sortLabel="sortColumn"
                  :order="sortOrder"
                  @sort="setSortOrderColumn"
                  @items-per-page-change="setFetchRowsPartecipanteDoc"
                  @current-change="setCurrentPagePartecipanteDoc"
                  :enableItemsPerPageDropdown="false"
                >
                  <template v-slot:cell-download="{ row: data }">
                    <div class="btn-group">
                      <button
                        class="btn btn-sm dropdown p-1"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        @click.prevent="
                          downloadFile(data.guid_documento, data.path)
                        "
                      >
                        <img
                          src="/media/fit-media/PDF.png"
                          style="width: 3rem"
                        />
                      </button></div
                  ></template>
                  <template v-slot:cell-titolo="{ row: data }"
                    ><div class="tab-long">
                      {{ data.titolo }}
                    </div></template
                  >
                  <template v-slot:cell-descrizione="{ row: data }"
                    ><div class="tab-long">
                      {{ data.descrizione }}
                    </div></template
                  >
                  <template v-slot:cell-options="{ row: data }">
                    <div class="btn-group" v-if="puoCaricareDocumenti">
                      <button
                        class="btn btn-sm dropdown p-1"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        @click.prevent="deleteFile(data.guid_documento)"
                      >
                        <i class="bi bi-trash text-dark fs-4"></i>
                      </button></div
                  ></template>
                </Datatable>
              </div>
              <template v-if="puoCaricareDocumenti">
                <hr />
                <div class="row pb-4">
                  <div class="col-5">
                    <label class="fw-bold text-gray-700">Titolo</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      aria-label=""
                      v-model="titolo"
                    />
                  </div>
                  <!-- <div class="col-7">
                  <label class="fw-bold text-gray-700 ps-0"
                    >File <small>(formato consentito .pdf)</small></label
                  >
                  <div class="col-lg-12 d-flex ps-0">
                    <div
                      class="image-input image-input-outline"
                      data-kt-image-input="true"
                      style="background-image: url(media/avatars/blank.png)"
                    >
                      <label
                        class="btn btn-icon btn-active-color-primary bg-white m-0 p-0"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        title="Change file"
                      >
                        <i
                          class="bi bi-upload fw-bold text-gray-700 fs-6"
                        ></i>


                        <input
                          type="file"
                          name="avatar"
                          accept=".pdf, .doc, .jpeg, .ppt, .xls, .docx, .pptx, .xlsx, .jpg, .jpeg, .png"
                        />
                        <input type="hidden" name="avatar_remove" />

                      </label>
                    </div>
                    <input
                      type="text"
                      class="form-control ms-10"
                      placeholder=""
                      aria-label=""
                    />
                  </div>
                </div>-->
                </div>

                <div class="row pb-4">
                  <div class="col-md-12">
                    <label class="fw-bold text-gray-700">Descrizione</label>

                    <textarea
                      type="text"
                      class="form-control"
                      placeholder=""
                      aria-label=""
                      v-model="descrizione"
                    />
                  </div>
                </div>
                <!--quando clicco su aggiungi allegato visualizzo la form, possibilità di aggiungere + allegati (tipo membri consiglio)-->
                <DropZone
                  @files-dropped="
                    addFile($event, supportedFormatPartecipantiCorsi)
                  "
                  #default="{ dropZoneActive }"
                >
                  <label for="file-input-add-part" style="cursor: pointer">
                    <span v-if="dropZoneActive">
                      <span>Lascia qui il file</span>
                      <span class="smaller">per aggiungerlo</span>
                    </span>
                    <span v-else>
                      <span>Trascina qui il tuo file </span>
                      <span class="smaller">
                        oppure
                        <strong><em>clicca qui</em></strong>
                        per selezionare il file
                      </span>
                    </span>
                  </label>
                  <input
                    type="file"
                    id="file-input-add-part"
                    style="display: none"
                    :accept="supportedFormatPartecipantiCorsi"
                    @change="onInputChange"
                  />
                  <ul class="image-list" v-if="file.id">
                    <FilePreview
                      :key="file.id"
                      :file="file"
                      tag="li"
                      @remove="removeFile"
                    />
                  </ul>
                  <div class="row">
                    <div class="col-lg-11 form-text">
                      Tipi di file concessi:
                      {{ supportedFormatPartecipantiCorsi }}
                    </div>
                  </div>
                </DropZone>
                <!-- <div class="fw-bold text-center pb-6">
                <button class="fw-bold fs-6 text-gray-700">
                  <i class="bi bi-plus text-gray-700 fs-6"></i> aggiungi
                  allegato
                </button>
              </div> -->
                <span
                  type="button"
                  class="badge bg-secondary rounded blueFit fs-6"
                  @click="aggiungiDocumentoPartecipante"
                >
                  Salva
                </span>
              </template>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <span
            type="button"
            class="me-3 badge bg-light-danger rounded text-danger fs-6"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            Chiudi
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import {
  defineComponent,
  computed,
  getCurrentInstance,
  ref,
  watch,
  onMounted,
} from "vue";
import { useStore } from "vuex";

import DropZone from "../../Drag-and-Drop-File/DropZone.vue";
import FilePreview from "../../Drag-and-Drop-File/FilePreview.vue";
import useFileList from "@/composables/file-list";
import { alertFailed, alertSuccess } from "@/composables/swAlert";
import { sendFiles } from "@/requests/fileSender";

import { downloadElement } from "@/requests/downloadFiles";
import { delFile } from "@/requests/documentiSnmCorsi";
import isEnabled from "@/composables/isEnabled.js";
import { Event } from "@microsoft/applicationinsights-common";

import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import { useRedirectInsegnanti } from "@/composables/redirectInsegnanti";

export default defineComponent({
  name: "view-documenti-partecipante",
  components: { Datatable, DropZone, FilePreview, Loading },
  emits: ["refreshList"],
  props: {
    id_Corso: {
      type: [String, Number],
      required: true,
    },
    selectedIdPersona: {
      type: Number,
      required: true,
    },
    stagioneCorso: {
      type: Number,
      required: true,
    },
    id_tipo_corso: {
      type: Number,
      required: true,
    },
    puoCaricareDocumenti: {
      type: Boolean,
    },
    viewDocs: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const rowsToSkip = computed(() => store.getters.rowsToSkipPartecipanteDoc);
    const fetchRows = computed(() => store.getters.fetchRowsPartecipanteDoc);
    const sortColumn = computed(() => store.getters.sortColumnPartecipanteDoc);
    const sortOrder = computed(() => store.getters.sortOrderPartecipanteDoc);

    const getPartecipanteDocList = () => {
      store.dispatch("setStoreListData", {
        keys: {
          id_Persona: props.selectedIdPersona,
          id_Corso: parseInt(props.id_Corso),
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          sortcolumn: sortColumn.value,
          sortorder: sortOrder.value,
        },
        apiLink: globalApi.PARTECIPANTE_DOC_LIST,
        itemName: "partecipante_doc_list",
      });
    };

    const { file, addFile, removeFile } = useFileList();
    function onInputChange(e) {
      addFile(e.target.files, supportedFormatPartecipantiCorsi.value);
      e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
    }

    const descrizione = ref(null);
    const titolo = ref(null);
    const bloccato = ref(0);

    const aggiungiDocumentoPartecipante = async () => {
      if (!descrizione.value || !titolo.value) {
        alertFailed("Inserire i campi mancanti");
        return;
      }
      let bodyFormData = new FormData();
      bodyFormData.append("id_Corso", props.id_Corso);
      bodyFormData.append("id_Persona", props.selectedIdPersona);
      bodyFormData.append("descrizione", descrizione.value);
      bodyFormData.append("titolo", titolo.value);
      bodyFormData.append("bloccato", bloccato.value);
      bodyFormData.append("anno", props.stagioneCorso);
      bodyFormData.append("id_Tipo_Corso", props.id_tipo_corso);
      bodyFormData.append("file", file.value.file);

      await sendFiles(
        bodyFormData,
        globalApi.AGGIUNGI_DOCUMENTO_CORSI_PARTECIPANTI
      ).then((res) => {
        const { status, data } = res;
        if (status != 200) {
          file.value.status = false;
          alertFailed(
            data.message
              ? data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        } else {
          file.value.status = true;
          alertSuccess("Documento aggiunto con successo");
          getPartecipanteDocList();
          file.value = {};
          titolo.value = "";
          descrizione.value = "";
        }
      });
    };

    const isLoading = ref(false);

    const deleteFile = (idDoc) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "Una volta cancellato, non portai piu recuperare il file!",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Elimina",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await delFile(idDoc).then((res) => {
            isLoading.value = false;
            if (res.status == 200) {
              alertSuccess("File eliminato");
              getPartecipanteDocList();
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
          });
        }
      });
    };

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedpartecipante_doc_list")
    );

    watch(
      () => props.viewDocs,
      (value) => {
        console.log(loaded.value);
        if (loaded.value || loaded.value === undefined)
          getPartecipanteDocList();
      }
    );
    // onMounted(() => {
    //   getPartecipanteDocList();
    // });

    const setFetchRowsPartecipanteDoc = (e) => {
      store.commit("setFetchRowsPartecipanteDoc", e);
      getPartecipanteDocList();
    };
    const setCurrentPagePartecipanteDoc = (page) => {
      store.commit("setCurrentPagePartecipanteDoc", page);
      getPartecipanteDocList();
    };

    const supportedFormatPartecipantiCorsi = ref(".pdf");
    const tableHeader = ref([
      {
        key: "download",
        sortable: false,
      },
      {
        name: "Titolo",
        key: "titolo",
        sortable: false,
      },
      {
        name: "Descrizione",
        key: "descrizione",
        sortable: false,
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const downloadFile = (guid, path) => {
      downloadElement(guid, "partecipanti", path);
    };

    const { pagesLength, redirectInsegnanti, menuLoaded, hasBacheca } =
      useRedirectInsegnanti();

    return {
      downloadFile,
      supportedFormatPartecipantiCorsi,
      getPartecipanteDocList,
      partecipante_doc_list: computed(() =>
        store.getters.getStateFromName("resultspartecipante_doc_list")
      ),
      loaded,
      record: computed(() =>
        store.getters.getStateFromName("recordpartecipante_doc_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statuspartecipante_doc_list")
      ),
      setFetchRowsPartecipanteDoc,
      setCurrentPagePartecipanteDoc,
      currentPage: computed(() => store.getters.currentPagePartecipanteDoc),
      rowsToSkip: computed(() => store.getters.rowsToSkipPartecipanteDoc),
      fetchRows: computed(() => store.getters.fetchRowsPartecipanteDoc),
      sortColumn: computed(() => store.getters.sortColumnPartecipanteDoc),
      sortOrder: computed(() => store.getters.sortOrderPartecipanteDoc),
      tableHeader,
      file,
      addFile,
      removeFile,
      onInputChange,
      aggiungiDocumentoPartecipante,
      descrizione,
      titolo,
      bloccato,
      isEnabled,
      deleteFile,
      isLoading,

      pagesLength,
      redirectInsegnanti,
      menuLoaded,
      hasBacheca,
    };
  },
});
</script>

<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

.modal-dialog {
  margin-top: 3rem;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
  }
}

@media (max-width: 767.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
